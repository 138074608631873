<template>
  <template v-if="item != null">
    <ion-item :class="['entry-item', (!displayStatus.filteredVisibility) ? 'filtered' : undefined]" lines="full">
      <div class="identifier-container" slot="start">
        <ion-checkbox v-if="displayStatus.enableCheckbox" :value="selected" @ionChange="selectChanged($event.target.value)"></ion-checkbox>
        <ion-icon class="filter-matching-indicator" v-else-if="showFilterFoundIcon" :icon="searchSharp"></ion-icon> <!-- Indicator when it is found by the own properties. Only shown when no checkbox is visible -->
        <span class="identifier">{{ identifier }}</span>
      </div>
      <ion-label class="entry-label">
        <span class="wrapping-text">{{ item.name }}</span>
      </ion-label>
      <div v-if="displayStatus.allVisibility" class="select-amount" slot="end">
        <div id="values-container" :class="[(selectedFactorAmount > 0 && displayStatus.modeWithModifications) ? 'highlight': undefined, (displayStatus.modeWithModifications) ? 'input-enabled' : undefined]">
          <span class="factor-input-container">
              <input 
                :class="['factor-input', 'input-without-arrow', 'align-right']"
                type="number"
                inputmode="decimal"
                :step="step"
                :min="minimum"
                :max="maximum"
                v-model="selectedFactorAmount"
                :style="`--characters: ${getCharLength(selectedFactorAmount)}`"
                @blur="$event.target.value = selectedFactorAmount"
                @keyup="blurOnEnter"
                :disabled="!displayStatus.modeWithModifications"
                :placeholder="displayStatus.modeWithModifications ? noValuePlaceholder : '1'"/> <!-- Show always "1" when empty when not selecting --> <!-- FIXME iOS inputs do not allow decimal separator ",", even though the keyboard shows it! In all inputs everywhere! -->
          </span>
          <span class="equals-indicator">&equals;</span>
          <span class="value-container">
            <input 
                :class="['value-input', 'input-without-arrow', 'align-right']"
                type="text"
                :value="computedValueAmount"
                @blur="computedValueAmount = $event.target.value"
                :style="`--characters: ${(computedValueAmount != null) ? computedValueAmount.length : 1 || 1}`"
                @keyup="blurOnEnter"
                :disabled="!(displayStatus.modeWithModifications && displayStatus.modifyValue)"
                :placeholder="noValuePlaceholder"/>
            <span class="currency">€</span> <!-- TODO Adapt to different currencies -->
          </span> <!-- TODO Possibly use other number representation without potential rounding errors?-->
        </div>
        <div class="inputs" v-if="(displayStatus.modeWithModifications)">
          <ion-button :disabled="selectedFactorAmount >= maximum" :fill="(selectedFactorAmount > 0) ? 'solid' : 'outline'" @click="increaseFactor">
            <ion-label>+</ion-label>
          </ion-button>
          <ion-button :disabled="!(selectedFactorAmount)" :fill="(selectedFactorAmount > 0) ? 'solid' : 'outline'" @click="decreaseFactor">
            <ion-label>-</ion-label>
          </ion-button>
        </div>
      </div>
    </ion-item>
  </template>
</template>

<script>
import { IonItem, IonLabel, IonButton, IonCheckbox, IonIcon } from '@ionic/vue';
import { defineComponent, ref, computed, watch } from 'vue';

import { blurOnEnter } from '@/utils/interaction';

import { searchSharp } from 'ionicons/icons';

import { useI18n } from "@/utils/i18n";
import { scaleCurrencyValueByFactor, parseCurrencyToCents } from '@/utils/services';

import _ from 'lodash';

export default defineComponent({
  name: 'ServiceItem',
  components: { IonItem, IonLabel, IonButton, IonCheckbox, IonIcon },
  props: {
    identifier: [String, Number],
    item: Object,
    selectMode: {
      type: Boolean,
      default: false
    },
    editMode: {
      type: Boolean,
      default: false
    },
    previewMode: {
      type: Boolean,
      default: false
    },
    minimum: {
      type: Number,
      default: 1
    },
    maximum: {
      type: Number,
      default: undefined
    },
    step: {
      type: Number,
      default: 0.5
    },
    modifyValue: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Boolean,
      default: false
    },
    visible: {
      type: Boolean,
      default: false
    },
    filteredVisibility: {
      type: Boolean,
      default: true
    },
    showFilterFoundIcon: {
      type: Boolean,
      default: false
    },
    resetCounter: Number, //Triggers a reset of this item on change!
    selectedFactor: Number,
    value: [String, Number],
    passive: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:selectedFactor', 'update:value', 'update:selected'],
  setup(props, context) {
    const i18n = useI18n();

    const selectedFactorAmount = ref(null);

    const valueAmountCents = ref(null);

    const noValuePlaceholder = '—';

    const emit = function(emitTarget, value) {
      if (!(props.passive)) context.emit(emitTarget, value);
    }

    const displayStatus = computed(() => {
      let status = {
        selectMode: (props.selectMode) ? true : false,
        editMode: (props.editMode) ? true : false,
        previewMode: (props.previewMode) ? true : false,
        filteredVisibility: (props.filteredVisibility) ? true : false,
        visible: (props.visible) ? true : false,
        modifyValue: (props.modifyValue) ? true : false
      }

      status.modeWithModifications = (status.editMode || status.previewMode);
      status.allVisibility = (status.visible && status.filteredVisibility);
      status.enableCheckbox = status.allVisibility && status.selectMode && !status.modeWithModifications;

      return status;
    })

    const validateFactor = function(newValue, replaceMinimumWithNull = true){
      let newNumberValue = parseFloat(newValue);
      //Clip to bounds
      if (newNumberValue < props.minimum) {
        if (replaceMinimumWithNull) return null;
        else return props.minimum;
      }
      if (props.maximum != null && newNumberValue > props.maximum) return props.maximum;
      if (Number.isNaN(newNumberValue)) newNumberValue = null;
      return newNumberValue;
    }

    const updateValueAmount = function(newValue) {
      let newParsedValue = null;
      if (newValue != null && newValue.length > 0) {
        newParsedValue = parseCurrencyToCents(newValue);
      }

      let valueChanged = (newParsedValue !== scaleCurrencyValueByFactor(valueAmountCents.value, selectedFactorAmount.value));

      if (valueChanged) {
        valueAmountCents.value = newParsedValue;
        //Reset the factor if the value changed manually
        updateSelectedFactorAmount(null);
        //Turn the value into a correctly formatted string
        let stringValue = newParsedValue / 100;
        if (props.editMode && props.modifyValue) emit('update:value', stringValue.toLocaleString(i18n.locale.value, {minimumFractionDigits: 2}));
      }
    }

    const computedValueAmount = computed({
      get: () => {
        if (valueAmountCents.value != null) {
          let scaledValue = scaleCurrencyValueByFactor(valueAmountCents.value, selectedFactorAmount.value);

          if (scaledValue != null) {
            scaledValue = scaledValue / 100;
            return scaledValue.toLocaleString(i18n.locale.value, {minimumFractionDigits: 2});
          }
        }
        return null;
      },
      set: updateValueAmount
    });

    //Watch for a reset trigger
    watch(() => props.resetCounter, (newResetCounter, oldResetCounter) => {
      //The reset count changed, so a reset was triggered
      if (newResetCounter != oldResetCounter) {
        //Set to original value or null if it doesn't exist
        let updatedValueAmountCents = null;
        if (props.value != null) {
          updatedValueAmountCents = parseCurrencyToCents(props.value);
        }
        //Only actually update on change
        if (updatedValueAmountCents != valueAmountCents.value) {
          valueAmountCents.value = updatedValueAmountCents;
        }

        //Set to original value or null if it doesn't exist
        let updatedSelectedFactor = null;
        if (props.selectedFactor != null) {
          updatedSelectedFactor = validateFactor(props.selectedFactor, true);
        }
        //Only actually update on change
        if (updatedSelectedFactor != selectedFactorAmount.value) {
          selectedFactorAmount.value = updatedSelectedFactor;
        }
      }
    });

    watch(() => props.value, (newValue, oldValue) => {
      //Only trigger on value changes
      if (newValue != null && (oldValue == null || oldValue != newValue)) {
        let newParsedValue = parseCurrencyToCents(newValue);

        valueAmountCents.value = newParsedValue;

        //If the value got changed in parsing return it via the emit
        if (props.editMode && props.modifyValue && (newValue != (newParsedValue / 100))) {
          //Turn the value into a correctly formatted string
          let stringValue = newParsedValue / 100;
          emit('update:value', stringValue.toLocaleString(i18n.locale.value, {minimumFractionDigits: 2}));
        }
      }
    }, { immediate: true });

    watch(() => props.selectedFactor, (newFactor) => {
      let newValidatedValue = validateFactor(newFactor, true);

      selectedFactorAmount.value = newValidatedValue;

      //If the value got changed in validation return it via the emit
      if (props.editMode && (newFactor != newValidatedValue)) emit('update:selectedFactor', newValidatedValue);
    }, { immediate: true });

    const updateSelectedFactorAmount = function(newValue, replaceMinimumWithNull) {
      //First validate the value
      let newValidatedValue = validateFactor(newValue, replaceMinimumWithNull);
      
      selectedFactorAmount.value = newValidatedValue;
      if (props.editMode) emit('update:selectedFactor', newValidatedValue);
    }

    const getCharLength = function(stringOrNumber) {
      let factorValue = stringOrNumber;

      if (_.isString(factorValue)) return factorValue.length;
      else if (_.isNumber(factorValue) && factorValue.toFixed != null) return factorValue.toFixed(2).length;
      
      return 1;
    }

    const selectChanged = function(newValue) {
      emit('update:selected', newValue)
    }

    const decreaseFactor = function() {
      let factorValue = (selectedFactorAmount.value != null && !(Number.isNaN(selectedFactorAmount.value))) ? selectedFactorAmount.value : 0;
      updateSelectedFactorAmount((factorValue - props.step));
    };

    const increaseFactor = function() {
      let factorValue = (selectedFactorAmount.value != null && !(Number.isNaN(selectedFactorAmount.value))) ? selectedFactorAmount.value : 0;
      updateSelectedFactorAmount((factorValue + props.step), false);
    }

    return {
      i18n,
      selectedFactorAmount,
      displayStatus,
      updateSelectedFactorAmount,
      getCharLength,
      computedValueAmount,
      selectChanged,
      noValuePlaceholder,
      decreaseFactor,
      increaseFactor,
      blurOnEnter,
      searchSharp
    }
  }
});
</script>

<style scoped>
.wrapping-text {
  white-space: normal!important;
  /* Prevent text selection in label */
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.entry-item {
  --padding-end: 0px;
  --inner-padding-end: 0px;
  --padding-start: 0px;
  --background: var(--background);
  background-color: var(--background);
}

.entry-item.filtered {
  display: none;
}

/* Remove line under last item */
.entry-item:last-child {
  --inner-border-width: 0px 0px 0px 0px;
}

.identifier-container {
  display: flex;
  margin: 0px;
  width: 50px;
  height: 100%;
  font-size: 0.75em;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
  padding-inline-start: 10px;
  padding-inline-end: 10px;
}

.identifier-container > * {
  margin: 5px 0px;
}

ion-checkbox {
  --background: var(--background);
}

.value-input, .currency, .factor-input, .factor-input-container::after {
  font-family: monospace;
  font-size: 0.8em;
  --input-padding: 4px;
  padding: var(--input-padding, 0px);
}

.highlight .value-input {
  font-weight: bold;
}

.select-amount {
  display: flex;
  flex-direction: row;
  margin: 10px 0px 10px 10px;
}

.inputs {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.inputs ion-button {
  --padding-top: 0px;
  --padding-bottom: 0px;
  --padding-start: 0px;
  --padding-end: 0px;
  margin-inline-end: 4px;
  width: 28px;
  height: 28px;
}

.inputs ion-button:first-of-type {
  margin-block-start: 0px;
}
.inputs ion-button:last-of-type {
  margin-block-end: 0px;
}

#values-container {
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding-inline-end: 10px;
  align-items: flex-end;
}

#values-container.highlight, #values-container.highlight span, #values-container.highlight input {
  color: var(--ion-color-primary-text)!important;
  -webkit-text-fill-color: var(--ion-color-primary-text)!important;
}

.factor-input-container, .value-container {
  display: flex;
  align-items: center;
}

.factor-input-container::after {
  padding-inline-start: 5px;
  content: 'x';
}

.currency {
  padding-inline-start: 5px;
}

.factor-input, .value-input {
  background: var(--background);
  --input-border-width: 1px;
  border: var(--input-border-width, 1px) solid var(--ion-color-medium-light, gray);
  border-radius: 4px;
}

.factor-input {
  min-width: 4em;
  --min-characters: 3;
  width: calc((1ch * max(var(--characters, 1), var(--min-characters, 5))) + (var(--input-padding, 0px) * 2) + (var(--input-border-width, 1px) * 2));
}

.value-input {
  --min-characters: 6;
  width: calc((1ch * max(var(--characters, 1), var(--min-characters, 5))) + (var(--input-padding, 0px) * 2) + (var(--input-border-width, 1px) * 2));
}

.factor-input:disabled::placeholder, .value-input:disabled::placeholder {
  color: var(--ion-text-color);
  opacity: 1;
}

.factor-input:disabled, .value-input:disabled {
  opacity: 1!important;
}

#values-container.input-enabled .factor-input:focus, #values-container.input-enabled .value-input:focus {
  outline-color: var(--ion-color-primary);
}

#values-container:not(.input-enabled) .factor-input, .value-input:disabled {
  border-color: var(--border-background);
  outline-color: var(--border-background);
}

.input-without-arrow::-webkit-outer-spin-button,
.input-without-arrow::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.align-right {
  text-align: right;
}

.input-without-arrow[type=number] {
  appearance: textfield;
  -moz-appearance: textfield;
}

.equals-indicator {
  font-size: 0.8em;
  margin-bottom: 0.2em;
  padding-inline-end: 4px;
}

.filter-matching-indicator {
  color: var(--ion-color-tertiary);
  font-size: 16px;
}
</style>