<template id="services-selection">
  <div class="version-container" v-if="usedVersion != null"><span>{{ i18n.$t('services.version') }} {{ usedVersion }}</span></div>

  <template v-for="(section, sectionIndex) in selectedFilteredCategorization" :key="sectionIndex">
    <div :class="['wrapping-title', (sectionIndex == 0) ? 'first-title' : undefined]">
      <ion-label v-if="section.section == CUSTOM_KEY">{{ i18n.$t('services.custom-section') }}</ion-label>
      <ion-label v-else>{{ i18n.$t('services.section') }} {{ section.section }} - {{section.descriptor}}</ion-label>
    </div>

    <ExtendableTextItem class="extendable-text" :content="section.general" :icon="informationCircleOutline"></ExtendableTextItem>

    <div v-if="(section.filterCounts != null) ? ((section.filterCounts.own || section.filterCounts.hierarchy) <= 0) : false" class="no-match-label">
      <ion-label>{{ i18n.$t('services.no_matches') }}</ion-label>
    </div>

    <ion-list>
      <CollapsibleList v-for="(category, categoryIndex) in section.categories" :key="categoryIndex"
        :showHeader="((section.section == CUSTOM_KEY) || category.name != null) && ((category.filterCounts != null) ? ((category.filterCounts.own || category.filterCounts.hierarchy) > 0) : true)"
        class="services-category-header"
        :title="(section.section == CUSTOM_KEY) ? i18n.$t('services.custom-category') : category.name"
        :open="getCategoryOpenState(category.name)"
        @update:open="(newState) => setCategoryOpenState(category.name, newState)"
        :primaryIndicator="category.selectedCount || null"
        :primaryIcon="checkmarkSharp"
        :successIndicator="getChangedCountForCategory(section.section, ((section.section == CUSTOM_KEY) ? CUSTOM_KEY : category.name))"
        :successIcon="pencilSharp"
        :tertiaryIndicator="(category.filterCounts != null) ? (category.filterCounts.total || null) : null"
        :tertiaryIcon="(filterTerm != null && filterTerm.length) ? searchSharp : undefined">

        <ExtendableTextItem class="extendable-text" :content="category.general" :icon="informationCircleOutline"></ExtendableTextItem>

        <!-- Only hide closed ones if it is non custom service list, because of many items -->
        <template v-if="!(isNonCustomServiceList) || getCategoryOpenState(category.name)">
          <ServiceItem v-for="(item, itemIndex) in category.items" :key="itemIndex"
            :identifier="item.identifier" :item="item.instance" :selectMode="selectMode" :editMode="editMode" :previewMode="previewMode" :modifyValue="item.custom"
            :selectedFactor="item.factor" @update:selectedFactor="(newFactor) => updateItemAttribute(item, itemIndex, newFactor, 'factor', {'section': ((section.section == CUSTOM_KEY) ? undefined: section.section), 'category': category.name})"
            :visible="getCategoryOpenState(category.name)" :filteredVisibility="getFilterVisibilityState(item, category)" :showFilterFoundIcon="item.filterByOwnProperties"
            :value="(item.instance != null) ? item.instance.value : undefined"
            @update:value="(newValue) => updateItemAttribute(item, itemIndex, newValue, 'value', {'section': ((section.section == CUSTOM_KEY) ? undefined: section.section), 'category': category.name})"
            :selected="item.selected" @update:selected="(newValue) => select(item, newValue)" :resetCounter="resetCounter"></ServiceItem>
        </template>

        <template v-if="category['sub_headings'] != null">
          <template v-for="(subHeading, subHeadingIndex) in category['sub_headings']" :key="subHeadingIndex">
            <ion-list-header v-if="(subHeading.filterCounts != null) ? ((subHeading.filterCounts.own || subHeading.filterCounts.hierarchy) > 0) : true">
              <ion-label>{{subHeading.name}}</ion-label>
            </ion-list-header>

            <ExtendableTextItem class="extendable-text" :content="subHeading.general" :icon="informationCircleOutline"></ExtendableTextItem>

            <template v-if="!(isNonCustomServiceList) || getCategoryOpenState(category.name)">
              <ServiceItem v-for="(item, itemIndex) in subHeading.items" :key="itemIndex"
                :identifier="item.identifier" :item="item.instance" :selectMode="selectMode" :editMode="editMode" :previewMode="previewMode" :modifyValue="item.custom"
                :selectedFactor="item.factor" @update:selectedFactor="(newFactor) => updateItemAttribute(item, itemIndex, newFactor, 'factor', {'section': ((section.section == CUSTOM_KEY) ? undefined: section.section), 'category': category.name, 'sub_heading': subHeading.name})"
                :visible="getCategoryOpenState(category.name)" :filteredVisibility="getFilterVisibilityState(item, subHeading)" :showFilterFoundIcon="item.filterByOwnProperties"
                :value="(item.instance != null) ? item.instance.value : undefined"
                @update:value="(newValue) => updateItemAttribute(item, itemIndex, newValue, 'value', {'section': ((section.section == CUSTOM_KEY) ? undefined: section.section), 'category': category.name, 'sub_heading': subHeading.name})"
                :selected="item.selected" @update:selected="(newValue) => select(item, newValue)" :resetCounter="resetCounter"></ServiceItem>
            </template>

            <template v-if="subHeading['sub_categories'] != null">
              <CollapsibleList v-for="(subCategory, subCategoryIndex) in subHeading['sub_categories']" :key="subCategoryIndex"
                :showHeader="subCategory.name != null && ((subCategory.filterCounts != null) ? ((subCategory.filterCounts.own || subCategory.filterCounts.hierarchy) > 0) : true)"
                class="services-sub-category-header"
                :title="subCategory.name"
                :open="defaultOpenLevel > 1" >

                <ExtendableTextItem class="extendable-text" :content="subCategory.general" :icon="informationCircleOutline"></ExtendableTextItem>

                <template v-if="!(isNonCustomServiceList) || getCategoryOpenState(category.name)">
                  <ServiceItem v-for="(item, itemIndex) in subCategory.items" :key="itemIndex"
                    :identifier="item.identifier" :item="item.instance" :selectMode="selectMode" :editMode="editMode" :previewMode="previewMode" :modifyValue="item.custom"
                    :selectedFactor="item.factor" @update:selectedFactor="(newFactor) => updateItemAttribute(item, itemIndex, newFactor, 'factor', {'section': ((section.section == CUSTOM_KEY) ? undefined: section.section), 'category': category.name, 'sub_heading': subHeading.name, 'sub_category': subCategory.name})"
                    :visible="getCategoryOpenState(category.name)" :filteredVisibility="getFilterVisibilityState(item, subCategory)" :showFilterFoundIcon="item.filterByOwnProperties"
                    :value="(item.instance != null) ? item.instance.value : undefined"
                    @update:value="(newValue) => updateItemAttribute(item, itemIndex, newValue, 'value', {'section': ((section.section == CUSTOM_KEY) ? undefined: section.section), 'category': category.name, 'sub_heading': subHeading.name, 'sub_category': subCategory.name})"
                    :selected="item.selected" @update:selected="(newValue) => select(item, newValue)" :resetCounter="resetCounter"></ServiceItem>
                </template>
              </CollapsibleList>
            </template>
          </template>
        </template>

        <template v-if="category['sub_categories'] != null">
            <CollapsibleList v-for="(subCategory, subCategoryIndex) in category['sub_categories']" :key="subCategoryIndex"
              :showHeader="subCategory.name != null && ((subCategory.filterCounts != null) ? ((subCategory.filterCounts.own || subCategory.filterCounts.hierarchy) > 0) : true)"
              class="services-sub-category-header"
              :title="subCategory.name"
              :open="defaultOpenLevel > 1" >

              <ExtendableTextItem class="extendable-text" :content="subCategory.general" :icon="informationCircleOutline"></ExtendableTextItem>

              <template v-if="!(isNonCustomServiceList) || getCategoryOpenState(category.name)">
                <ServiceItem v-for="(subItem, subItemIndex) in subCategory.items" :key="subItemIndex"
                  :identifier="subItem.identifier" :item="subItem.instance" :selectMode="selectMode" :editMode="editMode" :previewMode="previewMode" :modifyValue="subItem.custom"
                  :selectedFactor="subItem.factor" @update:selectedFactor="(newFactor) => updateItemAttribute(subItem, subItemIndex, newFactor, 'factor', {'section': ((section.section == CUSTOM_KEY) ? undefined: section.section), 'category': category.name, 'sub_category': subCategory.name})"
                  :visible="getCategoryOpenState(category.name)" :filteredVisibility="getFilterVisibilityState(subItem, subCategory)" :showFilterFoundIcon="subItem.filterByOwnProperties"
                  :value="(subItem.instance != null) ? subItem.instance.value : undefined"
                  @update:value="(newValue) => updateItemAttribute(subItem, subItemIndex, newValue, 'value', {'section': ((section.section == CUSTOM_KEY) ? undefined: section.section), 'category': category.name, 'sub_category': subCategory.name})"
                  :selected="subItem.selected" @update:selected="(newValue) => select(subItem, newValue)" :resetCounter="resetCounter"></ServiceItem>
              </template>
            </CollapsibleList>
          </template>
      </CollapsibleList>
    </ion-list>
  </template>
  
</template>

<script>
import { IonList, IonListHeader, IonLabel } from '@ionic/vue';
import { computed, defineComponent, ref } from 'vue';

import CollapsibleList from '@/components/CollapsibleList.vue';
import ServiceItem from '@/components/ServiceItem.vue';
import ExtendableTextItem from '@/components/ExtendableTextItem.vue';

import { pencilSharp, checkmarkSharp, searchSharp, informationCircleOutline } from 'ionicons/icons';

import { useI18n } from "@/utils/i18n";
import { serviceDefinitions, getServiceItems, transformListIntoHierarchicalOrder, orderHierarchicalElements, getIdentifyingAttributes, applyFunctionHierarchicallyToItems, CUSTOM_KEY } from '@/utils/services';

import _ from 'lodash';

export default defineComponent({
  name: 'ServicesSelection',
  components: { IonList, IonListHeader, IonLabel, CollapsibleList, ServiceItem, ExtendableTextItem },
  props: {
    type: String,
    selectMode: {
      type: Boolean,
      default: false
    },
    editMode: {
      type: Boolean,
      default: false
    },
    previewMode: {
      type: Boolean,
      default: false
    },
    defaultOpenLevel: {
      type: Number,
      default: 0
    },
    customElements: {
      type: Array,
      default: null
    },
    selection: {
      type: Array,
      default: () => []
    },
    defaultServiceType: { //Which type to use if no custom elements are supplied. Defaults to german GOT.
      type: String,
      default: 'GOT'
    },
    filterTerm: String
  },
  emits: ['elementsUpdated', 'update:selection'],
  setup(props, {emit}) {
    const i18n = useI18n();

    const serviceType = computed(() => props.defaultServiceType);

    //Contains items that have been added or changed. If it changed, id is set, otherwise just identifier is set. Key is built from identifier and id. ID is the API id for updates
    const changedItems = ref({});

    //A promise to synchronize parallel changes
    const itemChangePromise = ref(Promise.resolve());

    //Compare nested objects with comparator
    const compareItems = function(item, originalItem) {
      //Inner function to prepare objects for comparison
      let omitFunction = (value, key) => {
        //Treat empty values as equal, but only for non objects! So exclude those, no need to compare.
        if (value == null || (!(_.isObjectLike(value)) && (Number.isNaN(value) || value.length == 0))) return true; 
        
        //Exclude keys of comparison
        if (key == 'instance') return true;
        
        return false;
      };

      return _.isEqual(_.omitBy(item, omitFunction), _.omitBy(originalItem, omitFunction));
    }

    const resetCounter = ref(0);

    const resetItems = function() {
      //First catch all previous errors and then run this when the previous one finished
      itemChangePromise.value = itemChangePromise.value.catch(() => {}).then(() => {
        changedItems.value = {};
        //Reset all service items
        resetCounter.value = (resetCounter.value + 1);
      });
    }

    const updateItemAttribute = function(item, itemIndex, value, attribute = 'factor', orderingAttributes = {}) {
      //First catch all previous errors and then run this when the previous one finished
      itemChangePromise.value = itemChangePromise.value.catch(() => {}).then(() => {
        //Take either the id or if it does not exist, the index to uniquely identify the item (allows multiple of the same identifier when adding for the first time)
        let uniqueId = (item.id != null) ? item.id : itemIndex;
        let key = `${(uniqueId != null) ? uniqueId : '-'}_${(item.identifier != null) ? item.identifier : '-'}`;

        //Get existing changes to apply them to the old item
        let currentItem = changedItems.value[key] || {};

        let isValueEmpty = (value == null || Number.isNaN(value) || value.length == 0);

        let newItem = { ...item, ...currentItem, ...orderingAttributes, [attribute]: (isValueEmpty) ? null : value };

        let equalsOriginal = compareItems(newItem, item);
        
        //Delete from changes if it either matches the original item OR the value is empty, it is not an existing item (which should be set to null for removal from API)
        //and for custom items factor can be empty (so exclude from removal)
        if (equalsOriginal || (isValueEmpty && item.id == null && (!(item.custom) || attribute !== 'factor')) ) {
          delete changedItems.value[key]
        } else {
          //Create a copy and set the new factor
          changedItems.value[key] = newItem;
        }

        emit('elementsUpdated', changedItems.value);
      });
    }

    const isSelected = computed(() => {
      let selections = props.selection || [];

      return function(item) {
        if (selections != null && selections.length > 0 && item != null && _.intersectionWith(selections, [getIdentifyingAttributes(item)], _.isMatch).length > 0) {
          return true;
        }

        return false;
      }
    });

    const select = function(item, newValue) {
      if (newValue != null && item != null) {
        let identifyingAttributes = getIdentifyingAttributes(item);
        //Only add valid ones
        if (identifyingAttributes == null || Object.keys(identifyingAttributes).length <= 0) return;
        //Selected
        if (newValue) {
          //Add to array
          emit('update:selection', _.unionWith(props.selection, [identifyingAttributes], _.isMatch));
        } else { //Unselected
          //Remove from array
          emit('update:selection', _.differenceWith(props.selection, [identifyingAttributes], _.isMatch));
        }
      }
    }

    const getChangedCountForCategory = computed(() => {
      //Group by section or CUSTOM_KEY if it is custom
      let changedItemsBySection = _.groupBy(changedItems.value, (item) => {
        if (item.custom) {
          return CUSTOM_KEY;
        } else {
          return item['section'];
        }
      });
      
      //Count by category or CUSTOM_KEY if it is custom
      let categoryChangedCounts = _.mapValues(changedItemsBySection, (changedItemsInSection) => _.countBy(changedItemsInSection, (item) => {
        if (item.custom) {
          return CUSTOM_KEY;
        } else {
          return item['category'];
        }
      }));
      
      return function(sectionName, categoryName) {
        return _.get(categoryChangedCounts, [sectionName, categoryName], null);
      }
    });

    const categoryOpenStates = ref({});

    const getCategoryOpenState = computed(() => {
      return function(categoryName) {
        //Try to return the current state if it is set
        if (categoryName in categoryOpenStates.value && categoryOpenStates.value[categoryName] != null) {
          return categoryOpenStates.value[categoryName];
        }
        //Otherwise return default
        return props.defaultOpenLevel > 0;
      }
    });

    const setCategoryOpenState = function(categoryName, newState) {
      categoryOpenStates.value[categoryName] = newState;
    }

    const customElementListCategorization = computed(() => {
      if (props.customElements == null) return null;

      return orderHierarchicalElements(newestServiceDefinition.value.categorization, transformListIntoHierarchicalOrder(props.customElements));
    });

    //Returns the newest version of the defined serviceType (given by the set country)
    const newestServiceDefinition = computed(() => {
      if (serviceType.value != null && serviceDefinitions[serviceType.value] != null) {
        let newestVersion = serviceDefinitions[serviceType.value].newest;

        if (newestVersion != null && serviceDefinitions[serviceType.value].versions != null && serviceDefinitions[serviceType.value].versions[newestVersion] != null) {
          return serviceDefinitions[serviceType.value].versions[newestVersion];
        }
      }

      return null;
    });

    const serviceItemGetterReduceFunction = computed(() => {
      let defaultType;
      let defaultVersion;

      if (newestServiceDefinition.value != null) {
        defaultType = serviceType.value;
        defaultVersion = newestServiceDefinition.value['version'];
      }

      return function(categorization) {
        return applyFunctionHierarchicallyToItems(categorization, (result, category, levels) => {
          let items = getServiceItems(category.items, defaultType, defaultVersion);
          //Create an empty new category object to save all the mapped values, omit the values that will be mapped
          let newCategory = _.omit(category, ['items', 'categories', 'sub_headings', 'sub_categories']);

          //If items are not empty, set them
          if (items != null) newCategory.items = items;
          
          _.update(result, levels, (existingCategory) => {
            return {...newCategory, ...existingCategory};
          });

          return result;
        }, []);
      }
    })

    const usedCategorization = computed(() => {
      //If the custom elements are set, derive from those otherwise use the newest one for the correct country
      if (customElementListCategorization.value != null) return serviceItemGetterReduceFunction.value(customElementListCategorization.value);
      else if (newestServiceDefinition.value != null) {
        return serviceItemGetterReduceFunction.value(newestServiceDefinition.value.categorization);
      }

      return null;
    });

    const filteredCategorization = computed(() => {
      let currentFilterTerm = props.filterTerm;

      //No filter term entered, just return the items unfiltered
      if (currentFilterTerm == null || currentFilterTerm.trim().length <= 0) {
        return usedCategorization.value;
      } else if (usedCategorization.value != null) {
        let processedFilterTerm = currentFilterTerm.toLowerCase().trim(); //TODO Use normalization function to also remove spaces, both in searchTerm and values?

        return applyFunctionHierarchicallyToItems(usedCategorization.value, (result, category, levels, hierarchy) => {
          let filterCounts = {
            own: 0,
            hierarchy: 0,
            total: 0
          };

          let items = _.map(category.items, (item) => {
            let newItem = {...item};

            //Once a search is entered the default is false, otherwise it is never processed and undefined triggers the default behaviour of ServiceItem
            newItem.filterByOwnProperties = false;
            newItem.filterByHierarchy = false;

            let identifierString = String(newItem.identifier);
            //Either check for equality with the identifier; Not includes to only show true matches
            if (identifierString != null && identifierString.toLowerCase() == processedFilterTerm) {
              newItem.filterByOwnProperties = true;
            } else if (newItem.instance.name != null && newItem.instance.name.toLowerCase().includes(processedFilterTerm)) { //If not found search the name too
              newItem.filterByOwnProperties = true;
            }

            let additionalFilterCriteria = Object.values(_.omit(hierarchy, ['section']));
            //Always check both different criteria, every hierarchical element besides section
            if (additionalFilterCriteria != null) {
              for (let criterion of additionalFilterCriteria) {
                if (criterion != null && criterion.toLowerCase().includes(processedFilterTerm)) {
                  newItem.filterByHierarchy = true;
                  break; //No need to check the others
                }
              }
            }

            if (newItem.filterByOwnProperties) filterCounts.own++;
            if (newItem.filterByHierarchy) filterCounts.hierarchy++;

            return newItem;
          });

          //Create an empty new category object to save all the mapped values, omit the values that will be mapped
          let newCategory = _.omit(category, ['items', 'categories', 'sub_headings', 'sub_categories']);

          //If items are not empty, set them
          if (items != null) newCategory.items = items;
          
          _.update(result, levels, (existingCategory) => {
            return {...newCategory, ...existingCategory};
          });

          //The total visible ones in each level are either by their own count, or if it is 0 by the hierarchy count
          filterCounts.total = filterCounts.own || filterCounts.hierarchy;

          //Create a copy of the levels to bubble up the count of the levels. The format is always [section_index, "categories": category_index, ....], so remove in every step 2 from the levels to set it
          let bubbleCountLevels = [...levels];
          while (bubbleCountLevels.length > 0) {
            _.update(result, bubbleCountLevels, (existingCategory) => {
              //If it doesn't exist yet add it, otherwise add the new ones to create a total sum - Create a copy to not modify different unrelated objects!
              if (existingCategory.filterCounts == null) existingCategory.filterCounts = {...filterCounts};
              else {
                existingCategory.filterCounts.own += filterCounts.own;
                existingCategory.filterCounts.hierarchy += filterCounts.hierarchy;
                existingCategory.filterCounts.total += filterCounts.total;
              }
              return existingCategory;
            });
            bubbleCountLevels = _.dropRight(bubbleCountLevels, 2);
          }

          return result;
        }, []);
      }

      return null;
    });

    const selectedFilteredCategorization = computed(() => {
      let isSelectedFunction = isSelected.value;

      return applyFunctionHierarchicallyToItems(filteredCategorization.value, (result, category, levels) => {
        let selectedCount = 0;

        let items = _.map(category.items, (item) => {
          let newItem = {...item};

          if (isSelectedFunction(item)) {
            newItem.selected = true;
            selectedCount++;
          }

          return newItem;
        });

        //Create an empty new category object to save all the mapped values, omit the values that will be mapped
        let newCategory = _.omit(category, ['items', 'categories', 'sub_headings', 'sub_categories']);

        //If items are not empty, set them
        if (items != null) newCategory.items = items;
        
        _.update(result, levels, (existingCategory) => {
          return {...newCategory, ...existingCategory};
        });

        //Create a copy of the levels to bubble up the count of the levels. The format is always [section_index, "categories": category_index, ....], so remove in every step 2 from the levels to set it
        let bubbleCountLevels = [...levels];
        while (bubbleCountLevels.length > 0) {
          _.update(result, bubbleCountLevels, (existingCategory) => {
            //If it doesn't exist yet add it, otherwise add the new ones to create a total sum - Create a copy to not modify different unrelated objects!
            if (existingCategory.selectedCount == null) existingCategory.selectedCount = selectedCount;
            else {
              existingCategory.selectedCount += selectedCount;
            }
            return existingCategory;
          });
          bubbleCountLevels = _.dropRight(bubbleCountLevels, 2);
        }

        return result;
      }, []);
    });

    const getFilterVisibilityState = function(item, category) {
      //No search is active so they are both undefined --> visible
      if (item.filterByOwnProperties == null && item.filterByHierarchy == null) return true;
      //Otherwise either visible if found by own proprty or when none are found (count), but the hierarchy is found, show it
      return (item.filterByOwnProperties || (category.filterCounts != null && category.filterCounts.own == 0 && item.filterByHierarchy));
    }

    const isNonCustomServiceList = computed(() => (customElementListCategorization.value == null && newestServiceDefinition.value != null));

    //Set the version if no custom elements are present
    const usedVersion = computed(() => {
      if (isNonCustomServiceList.value) {
        return newestServiceDefinition.value.version;
      }

      return undefined;
    });

    return { 
      i18n,
      resetCounter,
      resetItems,
      usedCategorization,
      filteredCategorization,
      selectedFilteredCategorization,
      getFilterVisibilityState,
      isNonCustomServiceList,
      usedVersion,
      updateItemAttribute,
      isSelected,
      select,
      getChangedCountForCategory,
      getCategoryOpenState,
      setCategoryOpenState,
      CUSTOM_KEY,
      checkmarkSharp,
      pencilSharp,
      searchSharp,
      informationCircleOutline
    };
  }
});
</script>

<style scoped>
.no-match-label {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 20px;
}

.version-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-inline: 10px;
  padding-top: 5px;
}

.wrapping-title {
  padding: 20px 15px 5px;
  font-weight: 500;
  font-size: 1.35em;
  color: var(--ion-color-dark-tint, gray);
  white-space: normal!important;
  /* Prevent text selection in label */
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.wrapping-title.first-title {
  padding-top: 0px;
}

.services-category-header {
  --color: var(--ion-color-primary-text);
  color: var(--color);
}

.services-sub-category-header {
  margin-left: 20px;
  --color: var(--ion-color-medium);
  color: var(--color);
}

.extendable-text {
  background-color: var(--background);
  --background: var(--background);
}

ion-list {
  padding-top: 0px;
  padding-bottom: 0px;
  --border-background: var(--background);
  background-color: var(--background);
}

ion-list-header {
  font-size: 1.2em;
  font-weight: 500;
  --color: var(--ion-color-step-850, #262626);
}

ion-list-header ion-label {
  margin-top: 10px;
  margin-bottom: 5px;
}
</style>
