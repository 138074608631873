<template>
  <ion-item lines="full" v-if="hideWhenEmpty && computedContent != null" @click="toggleExtended" button :detail="false">
    <ion-icon v-if="icon != null" slot="start" :icon="icon"></ion-icon>
    <ion-label :class="[extended ? 'extended' : undefined]">
      <p class="extendable-text" v-for="(contentLine, contentLineIndex) in computedContent" :key="contentLineIndex"> 
        {{ contentLine }}
      </p>
    </ion-label>
  </ion-item>
</template>

<script>
import { IonItem, IonLabel, IonIcon } from '@ionic/vue';
import { defineComponent, computed, ref } from 'vue';

import { informationCircleOutline } from 'ionicons/icons';

export default defineComponent({
  name: 'ExtendableTextItem',
  components: { IonItem, IonLabel, IonIcon },
  props: {
    content: [Array, String], //String or array of strings, each one line
    hideWhenEmpty: {
      type: Boolean,
      default: true
    },
    icon: [Object, String]
  },
  setup(props) {
    //Always returns an array of Strings or null if empty / unset!
    const computedContent = computed(() => {
      if (props.content == null) return null;

      if (Array.isArray(props.content)) {
        if (props.content.length <= 0) return null;
        return props.content;
      } else {
        return [props.content];
      }
    });

    const extended = ref(false);

    const toggleExtended = function() {
      extended.value = !(extended.value);
    }

    return {
      computedContent,
      extended,
      toggleExtended,
      informationCircleOutline
    }
  }
});
</script>

<style scoped>
ion-item {
  --padding-start: 10px;
}

.extendable-text {
  font-size: 1em;
  white-space: pre;
  /* Prevent text selection in label */
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.extended .extendable-text {
  white-space: pre-wrap!important;
}

.extended .extendable-text:not(:last-of-type) {
  margin-bottom: 7px;
}

ion-icon {
  margin-inline-end: 10px;
}
</style>